import React from "react";
// import { RichText } from "prismic-reactjs";
//import { withPreview } from "gatsby-source-prismic-graphql";
import moment from "moment";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";

import "@scss/page.scss";
import "@scss/event.scss";

export const eventListQuery = graphql`
  query eventList($uid: String) {
    allPrismicEvents(filter: { uid: { eq: $uid }, lang: { eq: "en-us" } }) {
      edges {
        node {
          data {
            title {
              text
            }
          }
          uid
        }
      }
    }
    allPrismicEvent(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          data {
            title
            content {
              text
            }
            event_cta {
              link_type
            }
            event_thumbnail {
              url
            }
            date
          }
          uid
        }
      }
    }
  }
`;

const EventList = (props) => {
  var data = props.data;
  const currentPage = get(data, "allPrismicEvents.edges[0].node");
  const allEvents = get(data, "allPrismicEvent.edges");

  return (
    <Layout>
      <div className="general-page event-list" id={currentPage.uid}>
        <section>
          <h2>Events</h2>
          <ul className="event-list__items">
            {allEvents &&
              allEvents.map((event, index) => {
                const item = event.node.data;
                var date = moment(item.date);
                console.log("x", event.node);
                return (
                  <li>
                    <h2>
                      <Link to={`/events/${event.node.uid}/`}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.title.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br/>"
                            ),
                          }}
                        />
                      </Link>
                    </h2>
                    <time>
                      {date.format("dddd, MMMM Do YYYY")}
                      <span>|</span>
                      {date.format("h:mm a")}
                    </time>
                    <Link to={`/events/${event.node.uid}/`}>
                      <img src={item.event_thumbnail.url} />
                    </Link>
                  </li>
                );
              })}
          </ul>
        </section>
        {/* <section>
          <h2>Past Events</h2>
          <ul className="event-list__items">
            {allEvents &&
              allEvents.map((event, index) => {
                const item = event.node;
                var date = moment(item.date);
                return (
                  <li>
                    <h2>
                      <Link to={`/events/${item._meta.uid}/`}>
                        {item.title}
                      </Link>
                    </h2>
                    <time>
                      {date.format("dddd, MMMM Do YYYY")}
                      <span>|</span>
                      {date.format("h:mm a")}
                    </time>
                    <Link to={`/events/${item._meta.uid}/`}>
                      <img src={item.event_thumbnail.url} />
                    </Link>
                  </li>
                );
              })}
          </ul>
        </section> */}
      </div>
    </Layout>
  );
};

export default EventList;
